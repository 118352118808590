<template>
  <div class="container curriculum">
    <div class="clear">
      <Classify
        v-for="(time, index) in classifyList"
        :key="index"
        :title="time.title"
        :classifyList="time.lists"
        @classifyIncident="classifyIncident($event, time.type)"
      >
      </Classify>
      <div class="container-fluid title">
        共 <span>{{ currentTotal }}</span> 门课程
      </div>
      <template v-if="list.length > 0">
        <CourseRecom :liveBoradRecordedArr="list" v-loading="loading">
        </CourseRecom>
        <div class="page" v-if="currentTotal > 16">
          <el-pagination
            background
            :page-size="16"
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="currentTotal"
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <AbsolutelyEmpty></AbsolutelyEmpty>
      </template>
    </div>
  </div>
</template>
<script>
import list from '@/mixins/list';
import CLASS_IFY from '@/config/class_ify';
import Classify from './components/classify';
import CourseRecom from '@/components/courseRecom/index';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
import { dictDatamanyDictData } from '@/api/personal';
import {
  homePageSearch,
  homePageRecorded,
  homePageLiveBoradcast,
  homePageOnsiteTeaching
} from '@/api/api';
export default {
  name: 'Curriculum',
  mixins: [list],
  components: {
    Classify,
    CourseRecom,
    AbsolutelyEmpty
  },
  data() {
    return {
      classifyList: [], // 分类标题名称选择数组
      course_type: 'course_type', // 学科
      learning_section: 'learning_section', // 学段
      course_class: 'course_class', // 内容
      courseTypeId: '', // 学科id查询
      learningSectionId: '', // 学段id查询
      courseContentId: '', // 内容id查询
      natureDictValue: '' // 性质选择
    };
  },
  created() {
    this.getDictDatamanyDictData();
  },
  methods: {
    loadData() {
      this.allClassifyIncident();
    },
    cleanArray(actual) {
      const newArray = [];
      for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    },
    toQueryString(obj, name) {
      if (!obj) return '';
      return this.cleanArray(
        Object.keys(obj).map((key) => {
          if (obj[key] === undefined) return '';
          return name + '=' + obj[key];
        })
      ).join('&');
    },
    // 公共参数
    commonalityParameter() {
      const courseTypeIds = this.toQueryString(
        [this.courseTypeId],
        'courseTypeIds'
      );
      const learningSectionIds = this.toQueryString(
        [this.learningSectionId],
        'learningSectionIds'
      );
      const courseContentIds = this.toQueryString(
        [this.courseContentId],
        'courseContentIds'
      );
      const all =
        `pageNum=${this.pageNum}&pageSize=16&` +
        courseTypeIds +
        '&' +
        learningSectionIds +
        '&' +
        courseContentIds;
      const jsonData = {
        all: all
      };
      // const param = this.getParams(jsonData);
      return jsonData;
    },
    // 公共返回
    commonalityReturn(res) {
      if (res.code == 200) {
        const { list, total } = res.data;
        this.list = list;
        this.currentTotal = total;
        this.loading = false;
      }
    },
    // 全部课程
    getHomePageSearch() {
      const jsonData = this.commonalityParameter();
      homePageSearch(jsonData).then((res) => {
        this.commonalityReturn(res);
      });
    },
    // 录播 精品课
    getHomePageRecorded() {
      const jsonData = this.commonalityParameter();
      homePageRecorded(jsonData).then((res) => {
        this.commonalityReturn(res);
      });
    },
    // 直播课
    getHomePageLiveBoradcast() {
      const jsonData = this.commonalityParameter();
      homePageLiveBoradcast(jsonData).then((res) => {
        this.commonalityReturn(res);
      });
    },
    // 面授课
    getHomePageOnsiteTeaching() {
      const jsonData = this.commonalityParameter();
      homePageOnsiteTeaching(jsonData).then((res) => {
        this.commonalityReturn(res);
      });
    },
    // 公共方法
    allClassifyIncident() {
      if (this.natureDictValue == 'UPLOAD_VIDEO') {
        // 精品课
        this.getHomePageRecorded();
      } else if (this.natureDictValue == 'LIVE_BROADCAST') {
        // 直播课
        this.getHomePageLiveBoradcast();
      } else if (this.natureDictValue == 'ONSITE_TEACHING') {
        // 面授课
        this.getHomePageOnsiteTeaching();
      } else {
        this.getHomePageSearch();
      }
    },
    classifyIncident(itme, type) {
      const _this = this;
      _this.loading = true;
      _this.pageNum = 1;
      _this.currentTotal = 0;
      switch (type) {
        case 'nature': // 性质
          _this.natureDictValue = itme.dictValue;
          _this.allClassifyIncident();
          break;
        case 'course_type': // 学科
          _this.courseTypeId = itme.dictValue;
          _this.allClassifyIncident();
          break;
        case 'learning_section': // 学段
          _this.learningSectionId = itme.dictValue;
          _this.allClassifyIncident();
          break;
        case 'course_class': // 内容
          _this.courseContentId = itme.dictValue;
          _this.allClassifyIncident();
          break;
        default:
          break;
      }
    },
    getDictDatamanyDictData() {
      dictDatamanyDictData([
        this.course_type,
        this.learning_section,
        this.course_class
      ]).then((res) => {
        if (res.code == 200) {
          const { course_type, learning_section, course_class } = res.data;
          const list = CLASS_IFY;
          for (let i = 0; i < list.length; i++) {
            switch (list[i].type) {
              case 'learning_section': // 学段
                list[i].lists = list[i].list.concat(learning_section.list);
                break;
              case 'course_type': // 学科
                list[i].lists = list[i].list.concat(course_type.list);
                break;
              case 'course_class': // 内容
                list[i].lists = list[i].list.concat(course_class.list);
                break;
              default:
                break;
            }
          }
          this.classifyList = list;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.curriculum {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  box-sizing: border-box;
  .title {
    text-align: right;
    margin-bottom: 20px;
    .fontSize(@TEXT-SIZE-14);
    color: @TEXT-COLOR-888888;
    span {
      .fontSize(@TEXT-SIZE-18);
      color: @--color-primary;
    }
  }
  .comment();
}
</style>