<template>
  <div class="classify container-fluid">
    <div class="classify-clear">
      <div class="classify-title">{{ title }}</div>
      <div class="classify-box">
        <div class="classify-ul">
          <div
            class="classify-li"
            v-for="(itme, index) in classifyList"
            :key="index"
            :class="[classifyIndex == index ? 'active' : '']"
            @click="classifyIncident(itme, index)"
          >
            {{ itme.dictLabel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Classify',
  props: {
    title: {
      type: String,
      default: ''
    },
    classifyList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      classifyIndex: [0]
    };
  },
  methods: {
    classifyIncident(itme, index) {
      this.classifyIndex = index;
      this.$emit('classifyIncident', itme);
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.classify {
  .fontSize(@TEXT-SIZE-14);
  color: @TEXT-COLOR-888888;
  line-height: 30px;
  box-sizing: border-box;
  &-clear {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
  }
  &-title {
    width: 40px;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-363636;
    font-weight: bold;
  }
  &-ul {
    max-width: 1130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  &-li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 20px;
    border: 1px solid @TEXT-BG-E6E6E6;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-left: 15px;
    &.active {
      border: 1px solid @--color-primary;
      background: @--color-primary;
      color: @TEXT-COLOR-FFFFFF;
      transition: all 0.15s linear;
    }
    &.active:hover {
      background: @--color-primary-hover;
    }
    &:hover {
      background: @TEXT-BG-E6E6E6;
    }
  }
}
</style>